<template lang="">
    <div class="container">
        <div class="mb-3">
            <label class="form-label">اسم التصنيف</label>
            <input class="form-control" type="text" v-model="name" />
        </div>
        <div class="mb-3">
            <div class="form-group">
    <label for="exampleFormControlSelect1">جنس التصنيف</label>
    <select class="form-control" id="exampleFormControlSelect1" v-model="gender_id">
      <option  v-for="item in gender" :key="item.id" :value="item.id">{{item.name}}</option>
    </select>
  </div>

  
        </div>
        <div class="mb-3">
            <label class="form-label">الصورة</label>
            <img :src="image" alt="some image goes here"/>
            <input class="form-control" type="file" ref="image" @change="uploadImage" />
        </div>

        <div class="mb-3">
            <label class="form-label">الصورة الثانية</label>
            <img :src="second_image" alt="some image goes here"/>
            <input class="form-control" type="file" ref="second_image" @change="uploadSecondImage" />
        </div>
        <div class="mb-3">
            <button class="btn btn-dark" type="button" @click="updateCategory">حفظ</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name:'edit-category',
    data(){
        return{
            gender:[],
            name:'',
            image:'',
            second_image:'',
            gender_id:'',
        }
    },

    created(){
        this.getCategoryById();
        this.getGender();
    },
    methods:{

        async getCategoryById(){
        try{
            const response =  await axios.get(`https://boutiquette-store.ly/get-category/${this.$route.params.id}`);

            this.name = response.data.name;
            this.image = response.data.image;
            this.second_image = response.data.second_image;
            this.gender_id = response.data.gender_id;

            

        }catch(error){
            null
        }
    },
    async updateCategory(){
       try {
        let formData = new FormData();
        formData.append('name', this.name);
        formData.append('image', this.image);
        formData.append('second_image', this.second_image);
        formData.append('gender_id', this.gender_id);
        await axios.post(`https://boutiquette-store.ly/update-category/${this.$route.params.id}`, formData, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
        })
        this.$router.push('/e-commerce/categories');
       }catch(error){
        null
       }
    },
        async getGender(){
          try {
              const response = await axios.get('https://boutiquette-store.ly/genders')
              this.gender = response.data.data
          
             
       
          } catch (error) {
            null
          }
      },

  
        uploadImage() {
    this.image = this.$refs.image.files[0];
  },
  uploadSecondImage(){
    this.second_image = this.$refs.second_image.files[0];
  }
    }
    
}
</script>
<style lang="">
    
</style>